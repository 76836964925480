<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Top Placed Students</b-card-title>
    </b-card-header>

    <b-card-body>
      <div
        v-for="student in data"
        :key="student.uid"
        class="transaction-item align-items-start"
      >
        <b-media no-body class="w-100">
          <b-media-aside>
            <b-avatar
              square 
              size="42" :src="student.photo"
              class="text-secondary"
              variant="transparent"
              :text="avatarText(student.FullName)"
              style="border-radius: 16px !important;"
              :to="{
                name: 'student-profile',
                params: { studentId: student.uid },
              }"
            >
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <b-link :to="{
                name: 'student-profile',
                params: { studentId: student.uid },
              }" style="color:#043c57; font-weight: 600;" class="student-name transaction-title d-block mb-0">
            <!-- <h6 class="transaction-title mb-0"> -->
              {{ student.FullName }}
            <!-- </h6> -->
            </b-link>
            <small>{{ student.course }}</small>
          </b-media-body>
        </b-media>
        <div class="justify-content-start w-25"
        >
         <span class="text-nowrap text-body-heading  mr-1"
            >₹{{ parseFloat(student.ctc_upper).toFixed(1) }} LPA</span
          >
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,BLink
} from 'bootstrap-vue'
import firebase from "firebase/app";
import "firebase/firestore"
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BLink,
    BDropdownItem,
  },
  // props: {
  //   data: {
  //     type: Array,
  //     default: () => [],
  //   },
  // },
  data(){
    return{
      data:[]
    }
  },
  methods:{
    getTopPlacedStudents(){
      var collegecode = this.$store.state.mainUserCodes.CollegeCode;
      firebase.firestore().collection("CollegeCode").doc(collegecode).collection("PlacedStudents").orderBy("ctc_upper","desc").limit(5).get().then((docs) => {
        
        docs.forEach((stud) => {
          var uid =  stud.data().uid;
          var obj = stud.data()
          firebase.firestore().collection("StudentID").doc(uid).get().then((doc) => {
            obj.photo = doc.data().profilephoto;
            obj.course = doc.data().courseName;
            obj.batch = doc.data().batch
            this.data.push(obj)
          })
        })
      })
    },
    avatarText
  },
  created(){
     if(this.$store.state.mainUserCodes){
              this.getTopPlacedStudents()

    }


    //changing when store value changed
    // this.$store.watch(
    //   (state) => {
    //     return this.$store.state.mainUserCodes;
    //   },
    //   (newVal, oldVal) => {
    //     this.getTopPlacedStudents()
    //   },
    //   { deep: true }
    // );
  }
}
</script>
