<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col xl="4" md="6">
        <ecommerce-medal :data="data.congratulations" />
      </b-col>
      <b-col xl="8" md="6">
        <ecommerce-statistics :data="firebaseData.statisticsItems" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <!-- Revenue Report Card -->
      <b-col lg="8">
        <ecommerce-company-table :table-data="firebaseData.companyTable" />

      </b-col>
      <!--/ Revenue Report Card -->
      <b-col lg="4">
        <!-- <b-row class="match-height"> -->
        <!-- Bar Chart - Orders -->
        <!-- <b-col lg="6" md="3" cols="6">
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col> -->
        <!--/ Bar Chart - Orders -->
        <!-- <b-col lg="6" md="3" cols="6">
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col> -->
        <!-- <b-col lg="12" md="6"> -->
        <insights-chart :data="data.earningsChart" />
        <!-- </b-col>
        </b-row> -->
      </b-col>


    </b-row>

    <b-row class="match-height">
      <!-- Developer Meetup Card -->
      <b-col lg="4" md="6">
        <ecommerce-meetup :data="firebaseData.meetup" />
      </b-col>
      <!--/ Developer Meetup Card -->
      <!-- Company Table Card -->
      <b-col lg="8">
        <ecommerce-revenue-report :data="firebaseData.revenue" />

      </b-col>
      <!--/ Company Table Card -->



      <!-- Browser States Card -->
      <b-col lg="4" md="6">
        <ecommerce-browser-states :top-recruiters-data="firebaseData.topRecruiterbyCTC" />
      </b-col>
      <!--/ Browser States Card -->

      <!-- Goal Overview Card -->
      <b-col lg="4" md="6">
        <ecommerce-goal-overview :data="firebaseData.placementOverview" />
      </b-col>
      <!--/ Goal Overview Card -->

      <!-- Transaction Card -->
      <b-col lg="4" md="6">
        <ecommerce-transactions />
      </b-col>
      <!--/ Transaction Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import EcommerceMedal from "./EcommerceMedal.vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";
import EcommerceMeetup from "./EcommerceMeetup.vue";
import EcommerceBrowserStates from "./EcommerceBrowserStates.vue";
import EcommerceGoalOverview from "./EcommerceGoalOverview.vue";
import EcommerceTransactions from "./EcommerceTransactions.vue";
import InsightsChart from "./DashboardInsightsChart.vue"
export default {
  components: {
    BRow,
    BCol,

    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
    InsightsChart
  },
  data() {
    return {
      data: {
        congratulations:{
          name:''
        },
        earningsChart: {
          series: [53, 16, 31],
        },
      },
      firebaseData: {},
    };
  },
  created() {
    //changing initially
    if (this.$store.state.mainUserCodes) {
      this.$http.get("/main-dashboard/data").then((response) => {
        this.firebaseData = response.data;
        const userData = getUserData();
        this.data.congratulations.name =
          userData.username || userData.displayName.split(" ")[0];
      });
    }


    //changing when store value changed
    this.$store.watch(
      (state) => {
        return this.$store.state.mainUserCodes;
      },
      (newVal, oldVal) => {
        this.$http.get("/main-dashboard/data").then((response) => {
          // console.log(response)
          this.firebaseData = response.data;
        });
      },
      { deep: true }
    );
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

.student-name {
  &:hover {
    color: #039be5 !important;
    transition: all 0.25s ease;

  }
}
</style>
