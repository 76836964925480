<template>
  <b-card no-body class="card-browser-states">
    <b-card-header>
      <div>
        <b-card-title class="mb-1">Top Recruiters</b-card-title>
        <b-card-sub-title >
          Highest CTC till
          {{
            new Date().toLocaleString("default", {
              month: "long",
              year: "numeric",
            })
          }}
        </b-card-sub-title>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <div
        v-for="(browser, index) in topRecruitersData"
        :key="browser.companyCode"
        class="browser-states"
      >
        <b-media no-body class="align-items-center w-100">
          <b-media-aside class="mr-1">
            <b-avatar
              square
              :src="browser.companyLogo"
              size="42"
              class="text-secondary"
              variant="transparent" :to="{
                name: 'company-profile',
                params: { companyId: browser.companyCode },
              }"
              :text="avatarText(browser.companyName)"
              style="border-radius: 16px !important;"
            ></b-avatar>
          </b-media-aside>
          <b-media-body>
             <b-link
              :to="{
                name: 'company-profile',
                params: { companyId: browser.companyCode },
              }"
              class="d-block text-capitalize student-name"
              style="font-weight:600;color:#043c57"
            >
            <!-- <h6 class="align-self-center my-auto"> -->
              {{ browser.companyName }}
            <!-- </h6> -->
            </b-link>
          </b-media-body>
        </b-media>
        <div class="  justify-content-start w-25">
          <span class="text-nowrap text-body-heading  "
            >₹{{ parseFloat(browser.ctc).toFixed(1) }} LPA</span
          >
          <!-- <vue-apex-charts
            type="radialBar"
            height="30"
            width="30"
            :options="chartData[index].options"
            :series="chartData[index].series"
          /> -->
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle, BCardSubTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BDropdown,
  BDropdownItem,
  BAvatar, BLink
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { avatarText } from "@core/utils/filter";

/* eslint-disable global-require */
const $trackBgColor = "#e9ecef";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BCardSubTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BAvatar,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
    BLink
  },
  props: {
    topRecruitersData: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    avatarText,
  },
  data() {
    return {
      chartData: [],
      chartClone: {},
      chartColor: [
        $themeColors.primary,
        $themeColors.warning,
        $themeColors.secondary,
        $themeColors.info,
        $themeColors.danger,
      ],
      chartSeries: [54.4, 6.1, 14.6, 4.2, 8],
      
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%",
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
    };
  },
  
};
</script>
