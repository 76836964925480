<template>
    <!-- :img-src="require('@/assets/images/svg/flairboat_bg_dash.svg')" overlay text-variant="body"
        img-alt="card img" body-class="bg-overlay" -->
    <b-card v-if="data" class="insights-card">
        <b-card-header class="p-0 pb-2 ">
            <b-card-title>Insights</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
                <!-- Updated a second ago -->
                <u-icon icon="fi-rr-sparkles"></u-icon>
            </b-card-text>
        </b-card-header>
        <b-card-body class="p-0 d-flex flex-column">
            <!-- <b-row> -->
            <!-- <b-col cols="6"> -->

            <!-- <div class="font-small-2">
                        This Month
                    </div>
                    <h5 class="mb-1 ">
                        $4055.56
                    </h5> -->
            <div class="flex-fill mb-2">
                <b-card-text class=" font-small-3 mb-0">
                    <span>Encourage your students to complete their profiles. Add as many certifications or project they
                        have worked upon. </span><span class="font-weight-bolder"> Completed profiles have 75% more
                        chances
                        of getting hired.</span>
                </b-card-text>
            </div>
            <div>
                <app-collapse accordion class="font-small-2">

                    <app-collapse-item title="New Registrations this week">
                        You have total <span class="font-weight-bold"> 325</span> registrations this week.
                    </app-collapse-item>

                    <app-collapse-item title="New Job Openings this week" :isVisible="true">
                        You have total <span class="font-weight-bold"> 68 openings </span> this week, from the Sales and
                        Marketing industry
                    </app-collapse-item>

                    <!-- <app-collapse-item title="Collapse Item 3">
                    Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum
                    brownie
                    brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy.
                    Jelly
                    marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding
                    jelly
                    beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake.
                </app-collapse-item> -->

                </app-collapse>
            </div>

            <!-- </b-col> -->
            <!-- <b-col cols="6">
                    <vue-apex-charts height="120" :options="earningsChart.chartOptions" :series="data.series" />
                </b-col> -->
            <!-- </b-row> -->
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard, BRow, BCol, BCardTitle, BCardText, BCardBody, BCardHeader
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

const $earningsStrokeColor2 = '#28c76f66'
const $earningsStrokeColor3 = '#28c76f33'
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BCardTitle,
        BCardText,
        BCardBody,
        BCardHeader,
        VueApexCharts,
        AppCollapse,
        AppCollapseItem
    },
    props: {
        data: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            earningsChart: {
                chartOptions: {
                    chart: {
                        type: 'donut',
                        toolbar: {
                            show: false,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    legend: { show: false },
                    comparedResult: [2, -3, 8],
                    labels: ['App', 'Service', 'Product'],
                    stroke: { width: 0 },
                    colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
                    grid: {
                        padding: {
                            right: -20,
                            bottom: -8,
                            left: -20,
                        },
                    },
                    plotOptions: {
                        pie: {
                            startAngle: -10,
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        offsetY: 15,
                                    },
                                    value: {
                                        offsetY: -15,
                                        formatter(val) {
                                            // eslint-disable-next-line radix
                                            return `${parseInt(val)}%`
                                        },
                                    },
                                    total: {
                                        show: true,
                                        offsetY: 15,
                                        label: 'App',
                                        formatter() {
                                            return '53%'
                                        },
                                    },
                                },
                            },
                        },
                    },
                    responsive: [
                        {
                            breakpoint: 1325,
                            options: {
                                chart: {
                                    height: 100,
                                },
                            },
                        },
                        {
                            breakpoint: 1200,
                            options: {
                                chart: {
                                    height: 120,
                                },
                            },
                        },
                        {
                            breakpoint: 1045,
                            options: {
                                chart: {
                                    height: 100,
                                },
                            },
                        },
                        {
                            breakpoint: 992,
                            options: {
                                chart: {
                                    height: 120,
                                },
                            },
                        },
                    ],
                },
            },
        }
    },
}
</script>
<style lang="scss" >
.collapse-default {
    .card {
        border: none;
        background-color: transparent;
    }

    .card:first-child {
        border-top-left-radius: 1.143rem;
        border-top-right-radius: 1.143rem;
    }

    .card:last-child {
        border-bottom-left-radius: 1.143rem;
        border-bottom-right-radius: 1.143rem;
    }
}

.card .card-img-overlay.bg-overlay {
    background: rgba(239, 239, 239, 0.25);
}

.insights-card {
    .apexcharts-canvas .apexcharts-pie {
        .apexcharts-datalabel-label {
            font-size: 0.8rem;
            //   fill: #1b1b1b !important;
            fill: #fff !important;

        }

        .apexcharts-datalabel-value {
            //   fill: #043c57;
            fill: #fff;
            font-size: 1.286rem;
            font-weight: 500;
        }
    }
}

.card .card-img {
    height: 100%
}

.position-relative {
    height: -webkit-fill-available;

}
</style>
