<template>
  <b-card
    
    no-body
    class="card-developer-meetup"
  >
    <div class="bg-light-primary rounded-top text-center">
      <b-img
        :src="require('@/assets/images/illustration/email.svg')"
        alt="Meeting Pic"
        height="170"
      />
    </div>
    <b-card-body v-if="data">
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-start">
        <div class="meetup-day">
          <h6 class="mb-0">
            {{ weekday[new Date(data.startDate).getDay()] }}
          </h6>
          <h3 class="mb-0">
            {{ new Date(data.startDate).getDate() }}
          </h3>
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{ data.eventTitle }}
          </b-card-title>
          <b-card-text class="mb-0 font-small-3" style="line-height:1.2rem">
            {{ setDescription(data.eventDesc) }}
          </b-card-text>
        </div>
      </div>
      <!--/ metting header -->

      <!-- media -->
      <b-media
        v-for="media in data.mediaData"
        :key="media.avatar"
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              :icon="media.avatar"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ media.title }}
          </h6>
          <small>{{ media.subtitle }}</small>
        </b-media-body>
      </b-media>

      <!-- avatar group -->
      <!-- <b-avatar-group
        class="mt-2 pt-50"
        size="34px"
      >
        <b-avatar
          v-for="avatar in data.avatars"
          :key="avatar.avatar"
          v-b-tooltip.hover.bottom="avatar.fullName"
          :src="avatar.avatar"
          class="pull-up"
        />
        <h6 class="align-self-center cursor-pointer ml-1 mb-0">
          +42
        </h6>
      </b-avatar-group> -->

    </b-card-body>
    <b-card-body v-else>
      <div class="w-100 h-100 d-flex flex-column justify-content-center text-center align-items-center">
        <u-icon icon="fi-rr-calendar" class="text-secondary" size="48" style="opacity: 0.2; color:#043c57"/>
        <span style="opacity: 0.2;color:#043c57">No Upcoming Events</span>
         <b-button variant="primary" class="mt-2" :to="{name: 'apps-calendar'}">
                <span>Add Event</span>
      </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,BButton
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody, BButton
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data(){
    return{
    weekday : ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]

    }
  },
  methods:{
    setDescription(str){
      if(str.length > 100){
        var newStr = str.slice(0,100) + ' ...'
        return newStr
      }else return str
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
