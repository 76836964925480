<template>
  <b-card :img-src="require('@/assets/images/illustration/test-bg.png')" overlay text-variant="white" img-alt="card img"
    v-if="data" class="card-congratulation-medal font-white" id="welcomeCard">
    <!--body-class="bg-overlay"
     <b-img
      :src="require('@/assets/images/illustration/medal-bg.png')"
      class="congratulation-medal"
      alt="Medal Pic"
    /> -->
    <h5 class="font-weight-bold text-white">Welcome 🎉 {{ data.name.split(" ")[0] }}!</h5>
    <!-- <b-card-text class="font-small-3">
      to {{ $store.state.mainUserCodes.CollegeName }} Placements.
    </b-card-text> -->
    <h6 class="mb-1 mt-1 pt-50 text-white ">
      <b>5 new jobs</b> posted recently. View and track your students placements now
    </h6>
    <b-button variant="primary" :to="{ name: 'all-jobs' }">
      <span>View Jobs</span>
    </b-button>

  </b-card>
</template>

<script>
import {
  BCard, BCardText, BLink, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => { },
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
<style lang="scss" >
#welcomeCard {
  .card-img {
    -o-object-fit: cover;
    max-height: 180px;
    object-fit: cover;
  }

  .card-body {
    .btn-primary {
      // border: 1px solid #fff !important;
      background: #fff !important;
      color: #043c57 !important;
      // box-shadow: 0px 10px 20px rgb(255 255 255 / 5%);
      box-shadow: 2px 2px 2px rgba(255, 255, 255, 0.6), -2px -2px 2px rgba(4, 60, 87, 0.2);
      transition: all .3s ease-in-out, background 0s, color 0s, border-color 0s;

      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 4px 25px 0 rgba(#1b1b1b, 0.25);
      }
    }

    h5,
    h3,
    a {
      z-index: 1;
    }

    p {
      z-index: 1
    }
  }

  .congratulation-medal {
    max-width: 300px;
    right: 0;
    height: 100%;
    // z-index:-1

  }
}
</style>
